<template>
  <div class="footer-wrap">
    <div class="ehome-foot-box-bg">
      <b-row class="ehome-foot-box">
        <b-col sm="12" md="6" lg="4" xl="4" class="ehome-foot-box-item">
          <div class="ehome-foot-first">
            <!-- <img class="ehome-foot-logo" :src="footLogo" /> -->
            <div v-if="this.footerLogo == '' || this.footerLogo == null">
              <img
                src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230407105812.png"
                style="width: 300px" />
            </div>
            <div v-else>
              <img :src="this.footerLogo" style="width: 300px" />
            </div>
            <div class="ehome-foot-link-us">{{ $t("linkUs") }}</div>
            <div v-if="this.telephone == '' || this.telephone == null">
              <div class="ehome-foot-link-box">
                <a :href="'tel:' + linkPhone" class="ehome-foot-link-phone">{{ linkPhone }}</a>
                <span class="ehome-foot-link-time">{{ linkTime }}</span>
              </div>
              <div class="ehome-foot-link-box">
                <a :href="'tel:' + linkPhone1" class="ehome-foot-link-phone">{{ linkPhone1 }}</a>
                <span class="ehome-foot-link-time">{{ linkTime }}</span>
              </div>
              <div class="ehome-foot-link-box">
                <a :href="'tel:' + linkPhone2" class="ehome-foot-link-phone">{{ linkPhone2 }}</a>
                <span class="ehome-foot-link-time">{{ linkTime }}</span>
              </div>
            </div>
            <div v-else>
              <div class="ehome-foot-link-box">
                <a :href="'tel:' + telephone" class="ehome-foot-link-phone">{{ telephone }}</a>
                <span class="ehome-foot-link-time">{{ linkTime }}</span>
              </div>
            </div>

          </div>
        </b-col>
        <b-col sm="4" md="2" lg="1" xl="1" class="ehome-foot-box-item">
          <div class="ehome-foot-show-link-box">
            <div class="ehome-foot-title">
              {{ $t("footServiceCenter") }}
            </div>
            <div class="ehome-foot-line"></div>
            <div class="ehome-foot-subtitle-link" @click="dumpLink(item)" v-for="(item, index) in serviceCenterList"
              v-bind:key="index">
              {{ item.title }}
            </div>
          </div>

        </b-col>
        <b-col sm="4" md="2" lg="1" xl="1" class="ehome-foot-box-item">
          <div class="ehome-foot-show-link-box">
            <div class="ehome-foot-title">
              {{ $t("footProductCenter") }}
            </div>
            <div class="ehome-foot-line"></div>
            <div class="ehome-foot-subtitle-link" @click="dumpLink(item)" v-for="(item, index) in productCenterList"
              v-bind:key="index">
              {{ item.title }}
            </div>
          </div>

        </b-col>
        <b-col sm="4" md="2" lg="1" xl="1" class="ehome-foot-box-item">
          <div class="ehome-foot-show-link-box">
            <div class="ehome-foot-title">
              {{ $t("footCommonProblem") }}
            </div>
            <div class="ehome-foot-line"></div>
            <div class="ehome-foot-subtitle-link" @click="dumpLink(item)" v-for="(item, index) in commonProblemList"
              v-bind:key="index">
              {{ item.title }}
            </div>
          </div>

        </b-col>

        <b-col sm="12" md="12" lg="5" xl="5" class="ehome-foot-box-item">
          <!-- 了解更多 -->
          <div class="ehome-foot-show-link-box" v-if="this.shopName == null || this.shopName == ''">
            <div class="ehome-foot-title">
              {{ $t("footKnowMore") }}
            </div>
            <div class="ehome-foot-img-box">
              <div class="ehome-foot-img">
                <img class="ehome-foot-min-app-img" :src="minAppImg" />
                <div class="ehome-foot-img-title">{{ $t("ehomeFootMinApp") }}</div>
              </div>
              <!-- <div class="ehome-foot-img">
                <img class="ehome-foot-min-app-img" :src="publicImg" />
                <div class="ehome-foot-img-title">{{$t("ehomeFootPublic")}}</div>
              </div> -->
            </div>

          </div>
        </b-col>
      </b-row>
    </div>
    <div class="copyright-info">
      <b-container class="b-container">
        <b-row>
          <b-col sm="12" md="6" lg="3" xl="3">
            <div class="copyright">Copyright © 2022 ALL Rights Reserved</div>
          </b-col>

          <b-col sm="12" md="6" lg="3" xl="3" v-if="this.shopName">
            <div class="enterprise">{{ this.companyName }}</div>
          </b-col>


          <b-col sm="12" md="6" lg="3" xl="3" v-else>
            <div class="enterprise">深圳市装点美家国际软装有限公司</div>
          </b-col>

          <b-col sm="12" md="6" lg="3" xl="3">
            <div style="width:300px;margin:0 auto;">
              <a class="gaba" target="_blank" href="https://beian.miit.gov.cn"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img :src="require('@/../public/icon/gaba.png')" style="float:left;" />
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#868281;">粤公网安备
                  44030402005136号</p>
              </a>
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="3" xl="3">
            <div class="record-no"><span @click="toICP">{{ recordNo }}</span></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="bt-items">
              <div class="img-item" v-for="(item, index) in btItems" :key="index">
                <img :src="item" alt="">
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import dump from "../util/dump.js"
export default {
  data() {
    return {
      //底部logo
      // footLogo: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/90e33752-817e-40d2-9d56-a19e372cef25.png",
      // footLogo: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/75dd51e1-cac2-49ff-9954-597c0a8b8aab.png",
      linkPhone: "13728813249",
      linkPhone1: "18820210585",
      linkPhone2: "13802708947",
      linkTime: "（9:00~20:00）",
      footerLogo: "",
      telephone: '',
      shopName: '',
      companyName: '',
      //小程序二维码
      // minAppImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7bed2519-b649-4a5e-b2e3-6d4fe349f324.jpg",
      minAppImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/757fa8cd-a3da-4447-9050-d84311f9934d.jpg",
      //公众号二维码
      // publicImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/47fe7f8e-e78a-4dd9-9457-4a5ca6546429.jpg",
      // publicImg: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/9ecf8311-63ac-46d8-a9fa-5c74f3fe112e.jpg",
      serviceCenterList: [{
        "title": "蓝图构想",
        "link": "/videoTutorial/videoPlay?videoId=85&videoTypeId=1",
        type: 1
      }, {
        "title": "结缘软装",
        "link": "/videoTutorial/videoPlay?videoId=82&videoTypeId=1",
        type: 1
      }, {
        "title": "重启初心",
        "link": "/videoTutorial/videoPlay?videoId=86&videoTypeId=1",
        type: 1
      }
        //,{
        //         "title": "关于我们",
        //         "link": "/videoTutorial",
        //         type: 1
        //       },
        // {
        //   "title": "使用指南",
        //   "link": "/help_center.html?id=13",
        //   type: 2
        // },
        // {
        //   "title": "消息公告",
        //   "link": "/help_center.html?id=13",
        //   type: 2
        // },
        // {
        //   "title": "招商加盟",
        //   "link": "/help_center.html?id=13",
        //   type: 2
        // },
        // {
        //   "title": "商家入驻",
        //   "link": "/help_center.html?id=13",
        //   type: 2
        // }
      ],
      productCenterList: [{
        "title": "二维方案",
        "link": "/planeScheme",
        type: 1
      },
      // {
      //   "title": "三维方案",
      //   "link": "/threeDimensional",
      //   type: 1
      // }, 
      {
        "title": "3D模型",
        "link": "/tMode",
        type: 1
      },
      {
        "title": "商城",
        "link": "/mallIndex",
        type: 1
      }
      ],
      commonProblemList: [
        // {
        //          "title": "账户管理",
        //          "link": "3",
        //          type: 1
        //        },
        {
          "title": "作品发布",
          "link": "/videoTutorial/videoPlay?videoId=112&videoTypeId=5",
          type: 1
        },
        // {
        //   "title": "下载问题",
        //   "link": "#",
        //   type: 1
        // },
        {
          "title": "充值支付",
          "link": "/videoTutorial/videoPlay?videoId=99&videoTypeId=6",
          type: 1
        },
      ],
      recordNo: "粤ICP备2021128649号",
      btItems: [
        require("@/../public/icon/bt1.jpg"),
        require("@/../public/icon/bt2.jpg"),
        require("@/../public/icon/bt3.jpg"),
        require("@/../public/icon/bt4.jpg"),
        require("@/../public/icon/bt5.jpg")
      ]

    }
  },
  mounted() {
    this.footerLogo = this.cookie.getCookie("footerLogo")
    this.telephone = this.cookie.getCookie("telephone")
    this.shopName = this.cookie.getCookie("shopName")
    this.companyName = this.cookie.getCookie("companyName")
    console.log("子組件", this.footerLogo)
  },
  methods: {
    dumpLink(item) {
      dump.link(item);
    },
    // 跳转到备案网站
    toICP() {
      dump.link({
        type: 2,
        link: "https://beian.miit.gov.cn/#/Integrated/index"
      })
    }
  }

}
</script>

<style scoped="scoped" lang="scss">
@import "../assets/scss/style.scss";
@import "../assets/font/iconfont.css";

.ehome-foot-box-bg {
  width: 100%;
  background: #1E1E1E;
  padding: 2.6875rem 0;

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }

  .ehome-foot-box {
    .ehome-foot-box-item {
      display: flex;

      .ehome-foot-first {
        margin: auto;
        text-align: left;

        .ehome-foot-logo {
          width: 9.375rem;
          height: auto;
        }

        .icon-meiyidalogo {
          font-size: 58px;
          color: #fff;
        }

        .ehome-foot-link-us {
          font-size: 1.0625rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: $font-default-foot-link-color;
          padding-top: 5.3125rem;
        }

        .ehome-foot-link-box {
          .ehome-foot-link-phone {
            font-size: 1.8125rem;
            font-family: Brandon Text;
            font-weight: bold;
            color: #FFFFFF;
          }

          .ehome-foot-link-time {
            font-size: 1.0625rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: $font-default-foot-link-color;
            padding-left: 17px;
          }
        }
      }

      .ehome-foot-show-link-box {
        text-align: left;

        .ehome-foot-title {
          font-size: 17px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #FFFFFF;
        }

        .ehome-foot-line {
          width: 3.4375rem;
          height: 0.125rem;
          background: $font-default-foot-link-color;
          margin-top: 0.6875rem;
          margin-bottom: 2.0625rem;
        }

        .ehome-foot-subtitle-link {
          font-size: 0.9375rem;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: $font-default-foot-link-color;
          cursor: pointer;
          padding-bottom: 0.875rem;
        }

        .ehome-foot-img-box {
          display: flex;

          .ehome-foot-img {
            margin-top: 1.4375rem;
            margin-right: 2.625rem;

            .ehome-foot-min-app-img {
              width: 7.875rem;
              height: 7.875rem;

            }

            .ehome-foot-public-img {
              width: 7.875rem;
              height: 7.875rem;
            }

            .ehome-foot-img-title {
              font-size: 1.0625rem;
              font-family: Source Han Sans SC;
              font-weight: 400;
              color: $font-default-foot-link-color;
              text-align: center;
              padding-top: 1.1875rem;
            }
          }
        }

      }

    }
  }


}

.copyright-info {
  background-color: #342c2a;

  .b-container {
    padding-top: 30px;
    padding-bottom: 26px;
    font-size: 14px;
    color: #868281;

    .gaba {
      &:hover {
        p {
          color: #D19D7E !important;
        }
      }
    }

    .record-no {
      span {
        cursor: pointer;

        &:hover {
          color: #D19D7E;
        }
      }
    }

    .bt-items {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 30px;

      .img-item {
        padding: 0 20px;
      }
    }
  }
}
</style>
